import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/W5-BCHQFVOk">
    <SEO title="Sloth - Seven Things" />
  </Layout>
)

export default SermonPost
